import { createApi } from '@reduxjs/toolkit/query/react'

import { LIMIT_EXPORT } from '@/config/constants'
import { ADMIN_PROMOTION_CAMPAIGN_HISTORY } from '@/config/endpoints'
import { PromotionCampaignHistoryListRequest, PromotionCampaignHistoryListResponse } from '@/types/promotion-history'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'promotionCampaignHistory',
  tagTypes: ['PromotionCampaignHistory'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListPromotionCampaignHistory: builder.query<
      PromotionCampaignHistoryListResponse,
      PromotionCampaignHistoryListRequest
    >({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_HISTORY),
        method: 'GET',
      }),
      providesTags: ['PromotionCampaignHistory'],
    }),
    exportListPromotionCampaignHistory: builder.query<
      PromotionCampaignHistoryListResponse,
      PromotionCampaignHistoryListRequest
    >({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: PromotionCampaignHistoryListResponse = {
          success: true,
          code: 'OK',
          message: '',
          data: {
            items: [],
            metadata: {
              skip: '0',
              limit: '0',
              sortBy: 'latest',
              count: '0',
            },
          },
        }

        const limit = LIMIT_EXPORT
        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const promotionHistory = await baseQuery({
              ...composeRequest(
                {
                  ...request,
                  query: {
                    ...request.query,
                    limit,
                    skip,
                  },
                },
                ADMIN_PROMOTION_CAMPAIGN_HISTORY
              ),
              method: 'GET',
            })

            const {
              data: { items },
            } = promotionHistory.data as PromotionCampaignHistoryListResponse

            items.forEach((item) => {
              data.data.items.push(item)
            })

            skip += limit
            hasMore = items.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetListPromotionCampaignHistoryQuery,
  useLazyExportListPromotionCampaignHistoryQuery,
  util: promotionCampaignHistoryUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
