import { createApi } from '@reduxjs/toolkit/query/react'

import { GET_UPLOAD_URL } from '@/config/endpoints'
import { GetUploadUrlResponse, UploadRequest } from '@/types/assets'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'assets',
  tagTypes: ['assets'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getUploadUrl: builder.query<GetUploadUrlResponse, UploadRequest>({
      query: (request) => {
        return {
          ...composeRequest(request, GET_UPLOAD_URL),
          method: 'GET',
        }
      },
    }),
  }),
})

export const { useLazyGetUploadUrlQuery, util: getUploadUrlUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
