export enum Actions {
  Access = 'access',
}

export enum Privileges {
  DashboardRead = 'DR',
  DashboardExport = 'DE',
  TradeInRead = 'TIR',
  TradeInExport = 'TIE',
  StoreRead = 'SR',
  StoreCreate = 'SC',
  StoreUpdate = 'SU',
  StoreImport = 'SI',
  StoreExport = 'SE',
  StorePromoterManagementRead = 'SPMR',
  StorePromoterManagementCreate = 'SPMC',
  StorePromoterManagementUpdate = 'SPMU',
  StorePromoterManagementImport = 'SPMI',
  StorePromoterManagementExport = 'SPME',
  ProductListingManagementRead = 'PLMR',
  ProductListingManagementCreate = 'PLMC',
  ProductListingManagementUpdate = 'PLMU',
  ProductListingManagementImport = 'PLMI',
  ProductListingManagementExport = 'PLME',
  StaticManagementRead = 'SMR',
  StaticManagementUpdate = 'SMU',
  CmsUserManagementRead = 'CUMR',
  CmsUserManagementCreate = 'CUMC',
  CmsUserManagementUpdate = 'CUMU',
  CmsUserManagementDelete = 'CUMD',
  CmsRoleManagementRead = 'CRMR',
  CmsRoleManagementCreate = 'CRMC',
  CmsRoleManagementUpdate = 'CRMU',
  CmsRoleManagementDelete = 'CRMD',
  SettingRead = 'STR',
  SettingUpdate = 'STU',
  PromotionManagementRead = 'PMR',
  PromotionManagementCreate = 'PMC',
  PromotionManagementUpdate = 'PMU',
  PromotionManagementDelete = 'PMD',
  PromotionManagementExport = 'PME',
  BrandRead = 'BR',
  BrandCreate = 'BC',
  BrandUpdate = 'BU',
  WearableRead = 'ODWR',
  WearableCreate = 'ODWC',
  WearableUpdate = 'ODWU',
  CampaignManagementRead = 'CMR',
  CampaignManagementCreate = 'CMC',
  CampaignManagementUpdate = 'CMU',
  CampaignManagementDelete = 'CMD',
  CampaignManagementExport = 'CME',
}

export enum PeriodType {
  Unknown,
  Daily,
  Weekly,
  Monthly,
  Yearly,
}

export enum ModifiedByRole {
  ANONYMOUS_CUSTOMER = 'ANONYMOUS_CUSTOMER',
  ADMIN = 'ADMIN',
  PROMOTER = 'PROMOTER',
}
