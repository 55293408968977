import { createApi } from '@reduxjs/toolkit/query/react'

import { PromotionCampaignActiveStatus } from '@/config/constants'
import {
  ADMIN_PROMOTION_CAMPAIGN,
  ADMIN_PROMOTION_CAMPAIGN_CHANGE_PRIORITY,
  ADMIN_PROMOTION_CAMPAIGN_CHANGE_PRIORITY_ROW,
  ADMIN_PROMOTION_CAMPAIGN_CHANGE_STATUS,
  ADMIN_PROMOTION_CAMPAIGN_DETAIL,
  ADMIN_PROMOTION_CAMPAIGN_PROGRAM,
  ADMIN_PROMOTION_CAMPAIGN_PROGRAM_CHANGE_STATUS,
  ADMIN_PROMOTION_CAMPAIGN_PROGRAM_DETAIL,
  ADMIN_PROMOTION_CAMPAIGN_SAVE_DRAFT,
} from '@/config/endpoints'
import { BaseDetailRequest } from '@/types/common'
import {
  CampaignListRequest,
  CampaignListResponse,
  ProgramCampaignCreateRequest,
  ProgramCampaignDetailResponse,
  ProgramCampaignUpdateRequest,
  PromotionCampaignChangePriorityRequest,
  PromotionCampaignChangePriorityRowRequest,
  PromotionCampaignCreateRequest,
  PromotionCampaignCreateResponse,
  PromotionCampaignDetailResponse,
  PromotionCampaignListRequest,
  PromotionCampaignListResponse,
  PromotionCampaignSaveDraftRequest,
  PromotionCampaignUpdateRequest,
} from '@/types/promotion-campaign'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'promotionCampaign',
  tagTypes: ['PromotionCampaign', 'ProgramCampaign'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListPromotionCampaign: builder.query<PromotionCampaignListResponse, PromotionCampaignListRequest>({
      query: (request) => {
        return {
          ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN),
          method: 'GET',
        }
      },
      providesTags: ['PromotionCampaign'],
    }),
    getListPromotionCampaignPast: builder.query<PromotionCampaignListResponse, PromotionCampaignListRequest>({
      query: (request) => {
        return {
          ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN),
          method: 'GET',
        }
      },
    }),
    getDetailPromotionCampaign: builder.query<PromotionCampaignDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_DETAIL),
        method: 'GET',
      }),
    }),
    changeStatusPromotionCampaign: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_CHANGE_STATUS),
        method: 'PATCH',
      }),
      invalidatesTags: ['PromotionCampaign'],
    }),
    changePriorityPromotionCampaign: builder.mutation<void, PromotionCampaignChangePriorityRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_CHANGE_PRIORITY),
        method: 'PATCH',
      }),
      invalidatesTags: ['PromotionCampaign'],
    }),
    createPromotionCampaign: builder.mutation<PromotionCampaignCreateResponse, PromotionCampaignCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN),
        method: 'POST',
      }),
    }),
    updatePromotionCampaign: builder.mutation<void, PromotionCampaignUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_DETAIL),
        method: 'PUT',
      }),
    }),
    deletePromotionCampaign: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_DETAIL),
        method: 'DELETE',
      }),
      invalidatesTags: ['PromotionCampaign'],
    }),
    exportListPromotionCampaign: builder.query<PromotionCampaignListResponse, PromotionCampaignListRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: PromotionCampaignListResponse = {
          success: true,
          code: 'OK',
          message: '',
          data: {
            items: [],
            metadata: {
              skip: '0',
              limit: '0',
              sortBy: 'latest',
              count: '0',
            },
          },
        }

        const newRequest: PromotionCampaignListRequest = { ...request }

        if (newRequest.query && newRequest.query.filters && newRequest.query.filters.statusId) {
          newRequest.query.filters.statusId = String(PromotionCampaignActiveStatus.ACTIVE)
        }

        const limit = 100
        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const promotionCampaign = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...newRequest.query,
                    limit,
                    skip,
                  },
                },
                ADMIN_PROMOTION_CAMPAIGN
              ),
              method: 'GET',
            })

            const {
              data: { items: promotionCampaignItems },
            } = promotionCampaign.data as PromotionCampaignListResponse

            promotionCampaignItems.forEach((item) => {
              data.data.items.push(item)
            })

            skip += limit
            hasMore = promotionCampaignItems.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    getListCampaignProgram: builder.query<CampaignListResponse, CampaignListRequest>({
      query: (request) => {
        return {
          ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_PROGRAM),
          method: 'GET',
        }
      },
      providesTags: ['ProgramCampaign'],
    }),
    changeStatusProgramCampaign: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_PROGRAM_CHANGE_STATUS),
        method: 'PATCH',
      }),
      invalidatesTags: ['ProgramCampaign'],
    }),
    deleteProgramCampaign: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_PROGRAM_DETAIL),
        method: 'DELETE',
      }),
      invalidatesTags: ['ProgramCampaign'],
    }),
    createProgramCampaign: builder.mutation<void, ProgramCampaignCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_PROGRAM),
        method: 'POST',
      }),
      invalidatesTags: ['ProgramCampaign'],
    }),
    updateProgramCampaign: builder.mutation<void, ProgramCampaignUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_PROGRAM_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['ProgramCampaign'],
    }),
    getDetailProgramCampaign: builder.query<ProgramCampaignDetailResponse, BaseDetailRequest>({
      query: (request) => {
        return {
          ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_PROGRAM_DETAIL),
          method: 'GET',
        }
      },
    }),
    changePriorityRowPromotionCampaign: builder.mutation<void, PromotionCampaignChangePriorityRowRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_CHANGE_PRIORITY_ROW),
        method: 'PATCH',
      }),
      invalidatesTags: ['PromotionCampaign'],
    }),
    saveDraftPromotionCampaign: builder.mutation<void, PromotionCampaignSaveDraftRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_CAMPAIGN_SAVE_DRAFT),
        method: 'PUT',
      }),
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useLazyGetListPromotionCampaignQuery,
  useLazyGetListPromotionCampaignPastQuery,
  useChangeStatusPromotionCampaignMutation,
  useChangePriorityPromotionCampaignMutation,
  useCreatePromotionCampaignMutation,
  useUpdatePromotionCampaignMutation,
  useDeletePromotionCampaignMutation,
  useLazyExportListPromotionCampaignQuery,
  useLazyGetDetailPromotionCampaignQuery,
  useLazyGetListCampaignProgramQuery,
  useChangeStatusProgramCampaignMutation,
  useDeleteProgramCampaignMutation,
  useCreateProgramCampaignMutation,
  useUpdateProgramCampaignMutation,
  useLazyGetDetailProgramCampaignQuery,
  useChangePriorityRowPromotionCampaignMutation,
  useSaveDraftPromotionCampaignMutation,
  util: promotionCampaignUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
