import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import signed from '@/middlewares/signed'
import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/promotion-banners/$promotionBannerId/edit').createRoute({
  component: lazyRouteComponent(() => import('@/modules/promotion-banner/edit')),
  beforeLoad: ({ location, params, navigate }) => {
    signed({ location })

    validateParams(params, navigate, `/promotion-banners/${params.promotionBannerId}/edit`)
  },
})
