import { FileRoute, lazyRouteComponent } from '@tanstack/react-router'

import signed from '@/middlewares/signed'
import validateParams from '@/middlewares/validate-params'

export const Route = new FileRoute('/campaigns-history/$campaignId/').createRoute({
  component: lazyRouteComponent(() => import('@/modules/campaign-history/detail')),
  beforeLoad: ({ location, params, navigate }) => {
    signed({ location })

    validateParams(params, navigate, `/campaigns-history/${params.campaignId}`)
  },
})
