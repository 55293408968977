// Auth
export const AUTH_ANONYMOUS = 'v1/clients/sessions'
export const AUTH_LOGIN = 'v1/admin/sessions'
export const AUTH_LOGOUT = 'v1/admin/sessions'
export const AUTH_FORGOT_PASSWORD = '/v1/admin/passwords/forgot'
export const AUTH_RESET_PASSWORD = '/v1/admin/passwords/reset'
export const AUTH_SETUP_PASSWORD = '/v1/admin/passwords/setup'
export const AUTH_CHANGE_PASSWORD = 'v1/admin/passwords/change'
export const AUTH_VALIDATE_TOKEN = 'v1/token/validate'
export const AUTH_SETUP_PASSWORD_STORE = '/v1/store/passwords/setup'
export const AUTH_RESET_PASSWORD_STORE = '/v1/store/passwords/reset'
export const AUTH_SETUP_PIN_PROMOTER = '/v1/store/promoters/pin/setup'
export const AUTH_RESET_PIN_PROMOTER = '/v1/store/promoters/pin/reset'

export const ADMIN_PROFILE = 'v1/admin/me'

export const ADMIN_USER = 'v1/admin/users'
export const ADMIN_USER_DETAIL = 'v1/admin/users/:xid'
export const ADMIN_USER_STATUS = 'v1/admin/users/:xid/status'
export const ADMIN_USER_RESEND = 'v1/admin/users/:xid/resend-invitation'

export const ROLE = 'v1/admin/roles'
export const ROLE_DETAIL = 'v1/admin/roles/:xid'
export const ROLE_DELETE_REPLACEMENT = 'v1/admin/roles/:xid/replacement'
export const ROLE_COUNT = 'v1/admin/roles/:xid/users'
export const ROLE_PRIVILEGE = 'v1/admin/privileges'

export const ADMIN_STORE = 'v1/admin/stores'
export const ADMIN_STORE_DETAIL = 'v1/admin/stores/:xid'
export const ADMIN_STORE_CLOSED = 'v1/admin/stores/:xid/closed'
export const ADMIN_STORE_RESEND = 'v1/admin/stores/:xid/passwords/resend'
export const ADMIN_STORE_REACTIVATE = 'v1/admin/stores/:xid/active'

export const ADMIN_PROMOTER = 'v1/admin/promoters'
export const ADMIN_PROMOTER_DETAIL = 'v1/admin/promoters/:xid'
export const ADMIN_PROMOTER_STATUS = 'v1/admin/promoters/:xid/status'
export const ADMIN_PROMOTER_RESEND = 'v1/admin/promoters/:xid/resend-invitation'

export const ADMIN_PROMOTION = 'v1/admin/promotions'
export const ADMIN_PROMOTION_DETAIL = 'v1/admin/promotions/:xid'
export const ADMIN_PROMOTION_HISTORY = 'v1/admin/promotions/:xid/history'
export const ADMIN_PROMOTION_STATUS = 'v1/admin/promotions/:xid/toggle'
export const ADMIN_PROMOTION_DELETE = 'v1/admin/promotions/:xid'

export const ADMIN_TRADE_IN = 'v2/admin/trade-in'
export const ADMIN_TRADE_IN_DETAIL = 'v1/admin/trade-in/:xid'
export const ADMIN_TRADE_IN_COUNT = 'v1/admin/trade-in/count'
export const ADMIN_TRADE_IN_CANCEL = 'v1/admin/trade-in/:xid/canceled'
export const ADMIN_TRADE_IN_DETAIL_ASSESSMENT = 'v1/admin/trade-in/:xid/assessments'
export const ADMIN_TRADE_IN_DETAIL_ITEM = 'v1/admin/trade-in/:xid/assessments/:assessmentXid'
export const ADMIN_TRADE_IN_DETAIL_ITEM_PRE_ORDER = 'v1/admin/trade-in/:xid/pre-order/assessments/:assessmentXid'
export const ADMIN_TRADE_IN_DETAIL_ITEM_PRICE = 'v1/admin/assessments/:xid/prices'
export const ADMIN_TRADE_IN_DETAIL_PRE_ORDER_SEND_EMAIL = 'v1/admin/trade-in/:xid/pre-order/email'
export const ADMIN_TRADE_IN_EXPORT = 'v1/admin/trade-in/export'

export const STORE_ACCOUNT = 'v1/store-accounts'

export const LOCATION = 'v1/locations'

export const DEVICE = 'v1/devices'
export const DEVICE_OLD = 'v1/devices/old'
export const ADMIN_DEVICE = 'v1/admin/devices'
export const ADMIN_DEVICE_DETAIL = 'v1/admin/devices/:xid'

export const DEVICE_CATEGORY = 'v1/devices/categories'
export const DEVICE_CATEGORY_DETAIL = 'v1/devices/categories/:xid'
export const OLD_DEVICE_CATEGORY = 'v1/devices/old/categories'

export const ADMIN_WEARABLE = 'v1/admin/devices/wearable-old'
export const ADMIN_WEARABLE_DETAIL = 'v1/admin/devices/wearable-old/:xid'

export const ADMIN_SETTING_GENERAL = 'v1/admin/settings/GENERAL'
export const TERM_AND_CONDITION = 'v1/terms-and-conditions'

export const ADMIN_DASHBOARD_TRADE_IN = 'v1/admin/dashboard/trade-in/data'
export const ADMIN_DASHBOARD_TRANSACTION_OVERVIEW = 'v1/admin/dashboard/transactions/overview'
export const ADMIN_DASHBOARD_TRANSACTION_OVERVIEW_EXPORT = 'v1/admin/dashboard/export/transactions/overview'
export const ADMIN_DASHBOARD_NUMBER_OF_DEVICE = 'v1/admin/dashboard/devices/overview'
export const ADMIN_DASHBOARD_NUMBER_OF_DEVICE_EXPORT = 'v1/admin/dashboard/export/devices/overview'
export const ADMIN_DASHBOARD_GRADE_RESULT = 'v1/admin/dashboard/transactions/grades'
export const ADMIN_DASHBOARD_GRADE_RESULT_EXPORT = 'v1/admin/dashboard/export/devices/overview'
export const ADMIN_DASHBOARD_VENDOR_CONTRIBUTION = 'v1/admin/dashboard/partners/contribution'
export const ADMIN_DASHBOARD_VENDOR_CONTRIBUTION_EXPORT = 'v1/admin/dashboard/export/partners/contribution'
export const ADMIN_DASHBOARD_PROMOTER_TRANSACTION = 'v1/admin/dashboard/promoters/transactions'
export const ADMIN_DASHBOARD_PROMOTER_TRANSACTION_EXPORT = 'v1/admin/dashboard/export/promoters/transactions'
export const ADMIN_DASHBOARD_PROMOTER_INACTIVE_EXPORT = 'v1/admin/dashboard/export/promoters/inactive'
export const ADMIN_DASHBOARD_ACTIVE_STORE = 'v1/admin/dashboard/store/transactions/activities'
export const ADMIN_DASHBOARD_ACTIVE_STORE_EXPORT = 'v1/admin/dashboard/export/store/transactions/activities'
export const ADMIN_DASHBOARD_PROMOTER_ACTIVITY = 'v1/admin/dashboard/promoters/transactions/activities'
export const ADMIN_DASHBOARD_PROMOTER_ACTIVITY_EXPORT = 'v1/admin/dashboard/export/promoters/transactions/activities'
export const ADMIN_DASHBOARD_PROMOTER_NOT_LOGIN_EXPORT = 'v1/admin/dashboard/export/promoters/not-login'

export const ADMIN_LOCAL_PARTNER = 'v1/admin/partners'
export const ADMIN_LOCAL_PARTNER_DETAIL = 'v1/admin/partners/:xid'
export const ADMIN_LOCAL_PARTNER_CHANGE_STATUS = 'v1/admin/partners/:xid/status'

export const ADMIN_BRAND = 'v1/admin/brands'
export const ADMIN_BRAND_DETAIL = 'v1/admin/brands/:xid'

export const ADMIN_PROMOTION_CAMPAIGN = 'v1/admin/campaigns'
export const ADMIN_PROMOTION_CAMPAIGN_CHANGE_STATUS = 'v1/admin/campaigns/:xid/status'
export const ADMIN_PROMOTION_CAMPAIGN_CHANGE_PRIORITY = 'v1/admin/campaigns/:xid/priority'
export const ADMIN_PROMOTION_CAMPAIGN_DETAIL = 'v1/admin/campaigns/:xid'
export const ADMIN_PROMOTION_CAMPAIGN_PROGRAM = 'v1/admin/programs'
export const ADMIN_PROMOTION_CAMPAIGN_PROGRAM_CHANGE_STATUS = 'v1/admin/programs/:xid/status'
export const ADMIN_PROMOTION_CAMPAIGN_PROGRAM_DETAIL = 'v1/admin/programs/:xid'
export const ADMIN_PROMOTION_CAMPAIGN_HISTORY = 'v1/admin/programs/transactions/history'
export const ADMIN_PROMOTION_CAMPAIGN_CHANGE_PRIORITY_ROW = 'v1/admin/campaigns/priority/swap'
export const ADMIN_PROMOTION_CAMPAIGN_SAVE_DRAFT = 'v1/admin/campaigns/:xid/save/draft'

export const ADMIN_PROMOTION_BANNER = '/v1/admin/banners'
export const ADMIN_PROMOTION_BANNER_DETAIL = 'v1/admin/banners/:xid'
export const ADMIN_PROMOTION_BANNER_STATUS = '/v1/admin/banners/:xid/status'
export const ADMIN_PROMOTION_BANNER_DELETE = '/v1/admin/banners/:xid'
export const ADMIN_PROMOTION_BANNER_PRIORITY = '/v1/admin/banners/:xid/sequence'

export const GET_UPLOAD_URL = '/v1/assets/upload-url'
