import { createApi } from '@reduxjs/toolkit/query/react'

import {
  ADMIN_PROMOTION_BANNER,
  ADMIN_PROMOTION_BANNER_DELETE,
  ADMIN_PROMOTION_BANNER_DETAIL,
  ADMIN_PROMOTION_BANNER_PRIORITY,
  ADMIN_PROMOTION_BANNER_STATUS,
} from '@/config/endpoints'
import { BaseDetailRequest } from '@/types/common'
import {
  PromotionBannerChangePriorityRequest,
  PromotionBannerCreateRequest,
  PromotionBannerDetailResponse,
  PromotionBannerListRequest,
  PromotionBannerListResponse,
  PromotionBannerUpdateRequest,
} from '@/types/promotion-banner'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'promotionBanner',
  tagTypes: ['PromotionBanner'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    getListPromotionBanner: builder.query<PromotionBannerListResponse, PromotionBannerListRequest>({
      query: (request) => {
        return {
          ...composeRequest(request, ADMIN_PROMOTION_BANNER),
          method: 'GET',
        }
      },
      providesTags: ['PromotionBanner'],
    }),
    getListPromotionBannerPast: builder.query<PromotionBannerListResponse, PromotionBannerListRequest>({
      query: (request) => {
        return {
          ...composeRequest(request, ADMIN_PROMOTION_BANNER),
          method: 'GET',
        }
      },
    }),
    getDetailPromotionBanner: builder.query<PromotionBannerDetailResponse, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_BANNER_DETAIL),
        method: 'GET',
      }),
    }),
    createPromotionBanner: builder.mutation<void, PromotionBannerCreateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_BANNER),
        method: 'POST',
      }),
      invalidatesTags: ['PromotionBanner'],
    }),
    updatePromotionBanner: builder.mutation<void, PromotionBannerUpdateRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_BANNER_DETAIL),
        method: 'PUT',
      }),
      invalidatesTags: ['PromotionBanner'],
    }),
    changeStatusPromotionBanner: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_BANNER_STATUS),
        method: 'PATCH',
      }),
      invalidatesTags: ['PromotionBanner'],
    }),
    changePriorityPromotionBanner: builder.mutation<void, PromotionBannerChangePriorityRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_BANNER_PRIORITY),
        method: 'PATCH',
      }),
      invalidatesTags: ['PromotionBanner'],
    }),
    deletePromotionBanner: builder.mutation<void, BaseDetailRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_PROMOTION_BANNER_DELETE),
        method: 'DELETE',
      }),
      invalidatesTags: ['PromotionBanner'],
    }),
  }),
})

export const {
  useLazyGetListPromotionBannerQuery,
  useLazyGetListPromotionBannerPastQuery,
  useLazyGetDetailPromotionBannerQuery,
  useCreatePromotionBannerMutation,
  useUpdatePromotionBannerMutation,
  useChangeStatusPromotionBannerMutation,
  useChangePriorityPromotionBannerMutation,
  useDeletePromotionBannerMutation,
  util: promotionBannerUtil,
} = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
